.breadcrumb_bg {
	@include background("../img/breadcrumb.png");
}

.breadcrumb {
	position: relative;
	z-index: 1;
	height: 450px;
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #193e82;
		content: "";
		opacity: 0.7;
		z-index: -1;
	}

	.breadcrumb_tittle {
		h2 {
			color: $white_color;
			font-size: 50px;
			font-weight: 600;
			margin-bottom: 10px;
			text-transform: capitalize;

			@media #{$small_mobile} {
				font-size: 35px;
			}

			@media #{$large_mobile} {
				font-size: 35px;
			}

			@media #{$tab_device} {
				font-size: 40px;
			}

			@media #{$medium_device} {}
		}
	}
	.breadcrumb_content{
		@media #{$small_mobile} {
			float: left;
			text-align: left !important;
		}
		p {
			font-size: 15px;
			color: $white_color;
		}

		span {
			margin: 0px 5px;
			font-size: 12px;
		}
	}
}

.breadcrumb {
	margin: 0px !important;
}